import api from '@admin/api/service';

const baseUrl = propertyId =>
  `/singgahsini/api/property/${propertyId}/class-upgrade`;

export default {
  getPropertyClassUpgradeRoomList(propertyId) {
    return api.get(`${baseUrl(propertyId)}/room`);
  },

  postPropertyClassUpgradeActivation(propertyId, data) {
    return api.post(`${baseUrl(propertyId)}/activation`, data);
  },

  postPropertyClassUpgradeDeactivation(propertyId, data) {
    return api.post(`${baseUrl(propertyId)}/deactivation`, data);
  }
};
